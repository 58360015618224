<template>
  <div class="invite">
    <div class="background"></div>
    <div class="content">
      <picture>
        <source srcset="img/unsupported.avif" type="image/avif" />
        <source srcset="img/unsupported.png" type="image/png" />
        <img class="image" src="img/unsupported.png" alt="Unsupported" />
      </picture>
      <h1 class="title">Currently unavailable</h1>
      <div class="info">
        Try to update your telegram <br />
        or restart the application
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background {
  position: absolute;
  width: 100vw;
  height: 60vw;
  background: linear-gradient(180deg, rgba(45, 44, 42, 0) 0%, #2d2c2a 100%);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 0 5vw;
  text-align: center;
}

.title {
  position: relative;
  margin-top: 5vw;
  white-space: pre;
  font-size: 7.5vw;
  text-align: center;
}

.info {
  font-size: 5vw;
  white-space: pre;
}

.image {
  position: relative;
  width: 40vw;
  margin-top: 25vw;
  z-index: 1;
}
</style>
