<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import useStore from '@/store';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import StarIcon from '@/components/icons/StarIcon.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { PageName } from '@/types';

const store = useStore();
const router = useRouter();
const isClaim = ref(false);
const reward = ref(store.seasonReward);
const loading = ref(false);
const goBack = () => {
  router.back();
};
const claim = async (event: Event) => {
  event.stopPropagation();
  if (isClaim.value) {
    router.push({ name: PageName.Season });
    return;
  }
  try {
    loading.value = true;
    await store.claimSeasonReward();
  } finally {
    isClaim.value = true;
    loading.value = false;
  }
};

const text = computed(() => {
  return isClaim.value ? 'Close' : 'Claim now';
});

watch(
  () => store.seasonReward,
  newValue => {
    reward.value = newValue;
  },
);
</script>

<template>
  <div class="reward-view">
    <div class="back-button" @click="goBack">
      <ArrowLeftIcon />
    </div>
    <h1 class="title">
      Claim your<br />
      {{ reward?.title }} Rewards
    </h1>
    <div class="reward-place">
      <div class="cover">
        <div class="icon">
          <picture>
            <source srcset="img/coin.avif" type="image/avif" />
            <source srcset="img/coin.png" type="image/png" />
            <img class="coin" src="img/coin.png" alt="Coin" />
          </picture>
        </div>
        <div
          class="reward-amount"
          :style="{
            filter: isClaim ? 'blur(0px)' : 'blur(7px)',
          }"
        >
          {{ reward?.rewardScore }}
        </div>
        <span v-if="!isClaim"> Claim to reveal </span>
      </div>
      <StarIcon></StarIcon>
    </div>
    <div class="description">
      After claiming, you will know<br />
      your destiny and what your<br />
      karma has brought.
    </div>
    <div class="action-button__wrapper">
      <button class="action-button" @click="claim">
        {{ text }}
      </button>
    </div>
    <div v-if="loading" class="loading">
      <LoadingSpinner class="loading__spinner" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.reward-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 46vw;

  .back-button {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 10vw;
    background: #141414;
    top: 4vw;
    left: 4vw;
    border-radius: 100%;
    z-index: 1000;
    cursor: pointer;

    svg {
      width: 6vw;
      height: 6vw;
      fill: white;
    }
  }

  .title {
    position: relative;
    width: 92vw;
    margin-top: 10vh;
    font-size: 8vw;
    line-height: 100%;
    text-align: center;
  }

  .description {
    position: relative;
    margin-top: -5vh;
    width: 92vw;
    font-size: 4vw;
    text-align: center;
    color: #8b8b8b;
  }

  .reward-place {
    position: relative;
    display: flex;
    height: 50vh;
    width: 100vw;
    align-items: center;
    justify-content: center;

    .cover {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: linear-gradient(94.6deg, #f8e3bb -31.34%, #e9a062 105.5%);
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 2vh;
      box-shadow: 0px 0px 40px 0px rgba(236, 173, 115, 0.4);
      width: 50vw;
      height: 30vw;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.02em;
      color: rgba(20, 20, 20, 1);
    }
    .icon {
      width: 17vw;
      vertical-align: top;
      transform: translateY(-50%);
    }
    .reward-amount {
      position: relative;
      margin-top: -9vw;
      margin-bottom: 1vh;
      font-size: 32px;
      font-weight: 600;
    }
  }

  .action-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4vw 0;
    z-index: 1000;
    width: 100%;
    background: #e3232c;
    box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
    border-radius: 100vw;

    color: white;
    font-size: 4vw;
    font-weight: 700;
    border: 1px solid transparent;

    &__wrapper {
      position: fixed;
      display: flex;
      justify-content: center;
      left: 4vw;
      bottom: 15vw;
      width: 92vw;
    }
  }
}
</style>
<style lang="scss" scoped>
.loading {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.5vw;
  font-weight: 700;
  color: rgba(white, 0.5);
  z-index: 999;
  &__spinner {
    width: 30vw;
    margin: 35vw 0 35vw;
  }
  svg {
    fill: white;
  }
}
</style>
