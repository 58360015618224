<template>
  <header class="header">
    <span class="version">{{ version }}</span>
    <div v-if="showLogo" class="logo-wrapper">
      <img class="logo" alt="Soratopia" src="./assets/logo.svg" />
    </div>
  </header>
  <MainMenu v-if="showMainMenu" />
  <router-view />
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import useStore from './store';

import MainMenu from './components/MainMenu.vue';
import pkg from '../package.json';
import { wait } from './utils';
import { disableDevTools } from './utils/devTools';
import { AUTHORIZED_PAGES, MIN_LOADING_DELAY } from './const';
import { PageName } from './types';

type ParsedQueryString = typeof window.Telegram.WebApp.initDataUnsafe;

const router = useRouter();
const route = useRoute();
const store = useStore();

const version = pkg?.version ?? '';

const showLogo = computed(() => {
  return ![
    PageName.Earn,
    PageName.World,
    PageName.Location,
    PageName.Task,
    PageName.TaskSurvey,
    PageName.Season,
    PageName.SeasonReward,
  ].includes(route.name as PageName);
});

const showMainMenu = computed(() => {
  return [
    PageName.Tap,
    PageName.Earn,
    PageName.World,
    PageName.Location,
    PageName.Season,
  ].includes(route.name as PageName);
});

function getCheckString(initData: string) {
  const data = Object.fromEntries(new URLSearchParams(initData));
  return Object.keys(data)
    .filter(key => key !== 'hash')
    .map(key => `${key}=${data[key]}`)
    .sort()
    .join('\n');
}

function parseQueryString(queryString: string): ParsedQueryString {
  const queryObject: Record<string, unknown> = {};
  const queryParams = queryString.split('&');

  queryParams.forEach(param => {
    const [key, value] = param.split('=');
    const decodedValue = decodeURIComponent(value);

    try {
      queryObject[key] = JSON.parse(decodedValue);
    } catch {
      queryObject[key] = decodedValue;
    }
  });

  return queryObject as unknown as ParsedQueryString;
}

const log = (...data: unknown[]) => {
  if (!store.isProd) {
    console.log(...data);
  }
};

onMounted(async () => {
  const createdAt = performance.now();

  const env = await axios.get('/env.json', {
    headers: { 'Cache-Control': 'no-cache' },
  });
  store.env = env.data.env || 'PROD';

  if (store.isProd) {
    disableDevTools();
  }

  log('Fetching tasks...');
  const tasks = await axios.get('/tasks.json', {
    headers: { 'Cache-Control': 'no-cache' },
  });
  log('Tasks fetched:', tasks.data);

  store.baseApiUrl = env.data.baseApiUrl;
  store.tgBotUrl = env.data.tgBotUrl;
  store.httpAuth = env.data.httpAuth ?? null;
  const WebApp = window.Telegram?.WebApp;
  try {
    log('Initializing Telegram WebApp data...');
    const initDataRaw = WebApp.initData;
    const initData = parseQueryString(initDataRaw);
    log('Parsed initData:', initData, 'Raw initData:', initDataRaw);

    if (!initData?.user || !initDataRaw) {
      throw new Error('Invalid init data');
    }

    store.$state.telegramId = initData.user.id;
    store.$state.checkString = getCheckString(initDataRaw);
    store.$state.hash = initData.hash;
    log('Telegram WebApp data initialized successfully');
    WebApp?.setBackgroundColor?.('#0e0e0e');
    WebApp?.setHeaderColor?.('#0e0e0e');
    WebApp?.expand?.();
    WebApp?.disableVerticalSwipes?.();
  } catch (error) {
    console.error('Error during Telegram WebApp initialization:', error);
    if (!store.isDev) {
      return router.push({ name: PageName.UnsupportedPlatform });
    }
  }

  try {
    log('Fetching user data...');
    await store.fetchUser();
    log('User data fetched successfully');
    if (!route.name || !AUTHORIZED_PAGES.includes(route.name as PageName)) {
      router.push({ name: PageName.Tap });
    }
  } catch (error) {
    console.error('Error fetching user data:', error);

    const ref = route.query.startapp || WebApp?.initDataUnsafe?.start_param;

    if (ref && typeof ref === 'string') {
      try {
        log('Registering user with referral code:', ref);
        await store.registerUser(ref);
        log('User registered successfully');
        if (!route.name || !AUTHORIZED_PAGES.includes(route.name as PageName)) {
          router.push({ name: PageName.Tap });
        }
      } catch (registerError) {
        console.error(
          'Error during user registration or fetching user data:',
          registerError,
        );
        router.push({ name: PageName.Login });
      } finally {
        await wait(createdAt + MIN_LOADING_DELAY - performance.now());
        store.loading = false;
        log('Loading state set to false');
      }
    } else {
      router.push({ name: PageName.Login });
      log('No referral code, redirecting to Login');
    }
  } finally {
    await wait(createdAt + MIN_LOADING_DELAY - performance.now());
    store.loading = false;
    log('Loading state set to false');
  }
  try {
    log('Fetching locations...');
    await store.fetchLocations();
    log('Done! Fetching completed tasks...');
    await store.fetchCompletedTasks();
    log('Done! Fetching all tasks...');
    await store.fetchAllTasks();
    log('Done! Fetching seasons...');
    await store.fetchSeasons();
    log('Done! Fetching season reward...');
    await store.fetchSeasonReward();
    log('Done!');
  } catch (error) {
    console.error('Error fetching the data:', error);
  }
});
</script>

<style lang="scss">
html {
  background: #0e0e0e;
  color: white;
  font-family: 'Sora', sans-serif;
  font-size: 3.5vw;
  overflow-y: hidden;
}

body {
  font-family: 'Sora', sans-serif;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Firefox */
}

.header {
  position: relative;
}

#app {
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.version {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 10px;
  color: darkgray;
  opacity: 0.2;
  z-index: 1000;
}

.logo-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vw;
  width: 100vw;
  top: 2vw;
  z-index: 1000;
}

.logo {
  width: 50vw;
}

.ton-connect-button {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 40vw;
}
</style>
