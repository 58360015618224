<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import useStore from '@/store';
import LocationOnMap from '@/components/LocationOnMap.vue';
import TasksMenu from '@/components/TasksMenu.vue';
import { TasksListType, type Location } from '@/types/tasks';

const store = useStore();

const locations = computed(() => store.locations);
const season = ref<Location | null>(null);
const locationsExceptSeason = computed(() =>
  store.locations.filter(location => !location.isSeason),
);
const user = computed(() => store.user);
const reward = ref(store.seasonReward);

watch(
  user,
  newUser => {
    if (newUser) {
      store.fetchCompletedTasks();
      store
        .fetchSeasons()
        .then(() => {
          return store.fetchSeasonReward();
        })
        .then(() => {
          reward.value = store.seasonReward;
        });
    }
  },
  { immediate: true },
);
watch(
  store,
  newStore => {
    if (newStore) {
      const seasonLocation = locations.value.find(
        location => location.isSeason,
      );
      const seasonApi = store.getSeason();
      if (seasonLocation && seasonApi) {
        seasonLocation.title = seasonApi.title;
        season.value = seasonLocation;
      } else if (seasonLocation && reward.value) {
        seasonLocation.title = `The award for the ${reward.value.title}`;
        season.value = seasonLocation;
      }
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="world-view">
    <div class="animated-content">
      <div class="content">
        <picture class="background">
          <source srcset="img/tasks/world.avif" type="image/avif" />
          <source srcset="img/tasks/world.jpg" type="image/jpeg" />
          <img src="img/tasks/world.jpg" alt="Map" />
        </picture>

        <div class="background-blackout" />
        <div class="locations">
          <LocationOnMap
            v-if="season"
            class="location"
            :key="season.id"
            :location="season"
          />
          <LocationOnMap
            class="location"
            v-for="location in locationsExceptSeason"
            :key="location.id"
            :location="location"
          />
        </div>
      </div>
    </div>
    <TasksMenu :active="TasksListType.Map" />
  </div>
</template>

<style lang="scss" scoped>
$cubic-bezier: cubic-bezier(0.26, 0, 0.07, 1);
$animation-duration: 2s;

@keyframes map-animation {
  0% {
    transform: scale(1.25);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}
.world-view {
  position: fixed;

  .header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    width: 100vw;
    height: 12vh;
    font-size: 6vw;
    font-weight: 700;
    z-index: 1000;
  }

  .animated-content {
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    animation: map-animation $animation-duration $cubic-bezier forwards;
  }

  .content {
    position: relative;
    margin-bottom: 10vw;
  }

  .background {
    position: relative;
    display: flex;
    width: 100vw;
    left: 0;
    top: 0;
    object-position: 0 0;
  }

  .background-blackout {
    position: absolute;
    height: 30vh;
    width: 100vw;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(20, 20, 20, 0) 0%, #141414 106.35%);
    opacity: 0.3;
  }

  .locations {
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
  }

  .locations-counter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
    backdrop-filter: blur(5vw);
    border-radius: 100vw;
    border: 1px solid rgba(white, 0.2);
    animation: menu-animation 800ms;
    pointer-events: auto;
    font-size: 3vw;
    font-weight: 700;
    padding: 1vw 2vw;
    margin-top: 1vw;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vh;
    width: 80vw;
    margin-bottom: 5vh;
  }

  .item {
    display: flex;
  }

  .controls {
    position: fixed;
    display: flex;
    width: 100vw;
    bottom: 27vw;
    padding: 0 4vw;
    left: 0;
    z-index: 1000;
    pointer-events: none;
  }

  .previous-button,
  .next-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
    backdrop-filter: blur(5vw);
    border-radius: 100vw;
    border: 1px solid rgba(white, 0.2);
    animation: menu-animation 800ms;
    pointer-events: auto;
    padding: 3vw 4vw;

    svg {
      width: 6vw;
      height: 6vw;
      fill: white;
    }
  }

  .next-button {
    margin-left: auto;
  }
}
</style>
