<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import useStore from '@/store';
import TaskInList from './TaskInList.vue';
import BlackoutBottom from './BlackoutBottom.vue';
import { LocationId, type Location, type Task } from '@/types/tasks';

const route = useRoute();
const store = useStore();

const completedTasks = computed(() => store.completedTasks);

const locations = computed(() =>
  store.locations.filter(location => location.isSeason === false),
);
const season = ref(store.getSeason());
const activeLocations = computed(() =>
  locations.value.filter(location => getActiveTasks(location).length),
);

const filteredLocations = computed(() => {
  return showOnlyActive.value && activeLocations.value.length
    ? activeLocations.value
    : locations.value;
});

function getActiveTasks(location: Location) {
  const ids = location.tasks.filter(taskId => {
    return !completedTasks.value?.some(
      completedTask => completedTask.task_id === taskId,
    );
  });
  return ids.reduce<Task[]>((acc, id) => {
    const task = store.getTaskById(id);
    if (task) {
      acc.push(task);
    }
    return acc;
  }, []);
}

function getFilteredTasks(location: Location) {
  return showOnlyActive.value && activeLocations.value.length
    ? getActiveTasks(location)
    : location.tasks.reduce<Task[]>((acc, id) => {
        const task = store.getTaskById(id);
        if (task) {
          acc.push(task);
        }
        return acc;
      }, []);
}

const showOnlyActive = ref(true);

function switchFilter() {
  showOnlyActive.value = !showOnlyActive.value;
}

const nextTask = computed(() => {
  const nextTask = store.locations
    .map(location => location.tasks)
    .flat()
    .find(
      taskId =>
        !store.completedTasks?.some(
          completedTask => completedTask.task_id === taskId,
        ),
    );
  if (nextTask) {
    return store.getTaskById(nextTask);
  }
  return null;
});
const nextTaskCoverSrc = computed(
  () => `/img/tasks/tasks/${nextTask.value?.image}`,
);

onMounted(() => {
  setTimeout(() => {
    const locationId = route.params.locationId;
    if (typeof locationId !== 'string') {
      return;
    }
    const element = document.getElementById('location' + locationId);
    const isItFirstLocation =
      locations.value[0].id === LocationId(parseInt(locationId));
    if (element && !isItFirstLocation) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, 500);
});
</script>

<template>
  <div class="tasks-list">
    <div class="content">
      <div class="cover-background" v-if="nextTask">
        <picture>
          <source :srcset="nextTaskCoverSrc + '.avif'" type="image/avif" />
          <source :srcset="nextTaskCoverSrc + '.jpg'" type="image/jpeg" />
          <img :src="nextTaskCoverSrc + '.jpg'" alt="Quest in list cover" />
        </picture>
        <div class="cover-background-blackout"></div>
      </div>
      <div class="header">
        <h2>Quests</h2>
        <button
          v-if="activeLocations.length"
          class="filter-button"
          @click="switchFilter"
        >
          Show: {{ showOnlyActive ? 'Active' : 'All' }}
        </button>
      </div>
      <div class="location__tasks">
        <TaskInList
          v-if="season"
          class="next-task"
          :task="null"
          :next="false"
          :is-season="true"
        />
        <TaskInList
          v-if="nextTask"
          class="next-task"
          :task="nextTask"
          :next="true"
          :is-season="false"
        />
      </div>
      <div
        class="location"
        :id="'location' + location.id"
        v-for="location in filteredLocations"
        :key="location.id"
      >
        <div class="location__header">
          <h1>{{ location?.title ?? '' }} Quests</h1>
          <span>{{ location?.tasks.length ?? '' }}</span>
        </div>
        <div v-if="location && location.tasks.length" class="location__tasks">
          <TaskInList
            v-for="task in getFilteredTasks(location)"
            :key="task.id"
            class="task"
            :task="task"
            :next="false"
            :is-season="false"
          />
        </div>
      </div>
    </div>
  </div>
  <BlackoutBottom />
</template>

<style lang="scss" scoped>
$cubic-bezier: cubic-bezier(0.26, 0, 0.07, 1);
$animation-duration: 1s;

@keyframes list-animation {
  0% {
    transform: scale(1.25);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 100%;
  }
}

.tasks-list {
  position: relative;
  transform-origin: 50% 50vh;
  animation: list-animation $animation-duration $cubic-bezier forwards;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-bottom: 45vw;
}

.cover-background {
  position: absolute;
  width: 100%;
  height: 80vw;
  filter: blur(5vw);

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-blackout {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 92vw;
  padding: 4vw 0;

  h2 {
    font-size: 6vw;
    font-weight: 600;
  }
}

.filter-button {
  padding: 0 4vw;
  border-radius: 100vw;
  background: rgba(32, 30, 30, 1);
  font-size: 4vw;
  font-weight: 600;
}

.next-task {
  width: 92vw;
  margin-bottom: 1vw;
}

.location {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 18vw;
    padding: 0 4vw;

    h1,
    span {
      font-size: 6vw;
      font-weight: 600;
    }

    span {
      color: #8b8b8b;
    }
  }

  &__tasks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4vw;
    width: 92vw;
  }
}

.task {
  flex-shrink: 0;
}
</style>
