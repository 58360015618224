import { defineStore } from 'pinia';
import axios from 'axios';
import {
  checkIn,
  claimReferralReward,
  claimSeasonReward,
  fetchAllTasks,
  fetchCompletedTasks,
  fetchReferralCode,
  fetchReferrals,
  fetchSeasonReward,
  fetchSeasons,
  fetchUser,
  registerUser,
} from '@/utils/api';
import { REFERRALS_PER_PAGE } from '@/const';
import { getNow } from '@/utils/timeFunctions';
import {
  type Location,
  type TaskId,
  type Task,
  TaskType,
  TasksListType,
} from '@/types/tasks';
import type { Season, SeasonReward } from '@/types/season';
import type { Referral, User } from '@/types/api';

export default defineStore('root', {
  state: () => ({
    env: 'PROD' as 'PROD' | 'STAGE' | 'DEV',
    baseApiUrl: null as string | null,
    tgBotUrl: null as string | null,

    telegramId: null as number | null,
    checkString: null as string | null,
    hash: null as string | null,
    isFirstRender: true as boolean,
    user: null as User | null,
    referralCode: null as string | null,
    referrals: [] as Referral[],
    isFetchingReferrals: false,
    shouldResetReferrals: false,
    typeOfTasksView: TasksListType.Map,
    httpAuth: null as { username: string; password: string } | null,

    locations: [] as Location[],
    completedTasks: null as { task_id: TaskId; choice: number }[] | null,
    allTasks: null as Task[] | null,
    seasons: null as Season[] | null,
    seasonReward: null as SeasonReward | null,
    actualSeason: null as Season | null,
    loading: true,
  }),
  getters: {
    isDev: state => state.env === 'DEV',
    isProd: state => state.env === 'PROD',
    useMockData: state => {
      return state.env === 'DEV' && state.telegramId === null;
    },
    getTaskById: state => (id: TaskId) => {
      return state.allTasks?.find(task => task.id === id);
    },
    hasOutstandingTasks: state => () => {
      if (state.allTasks && state.completedTasks) {
        const allTaskIds = new Set(state.allTasks.map(task1 => task1.id));
        const completedTaskIds = new Set(
          state.completedTasks.map(task1 => task1.task_id),
        );
        for (const taskId of allTaskIds) {
          if (completedTaskIds.has(taskId)) {
            return true;
          }
        }
      }
      return false;
    },
    getSeasonTasks: state => (seasonId: number) => {
      return state.allTasks?.filter(task => task.seasonId == seasonId);
    },
    getUniqueSeasonTasks: state => (seasonId: number) => {
      const uniqueIds = new Set();
      const uniqueTasks: Task[] = [];
      state.allTasks?.forEach(task => {
        if (!uniqueIds.has(task.id)) {
          if (task.seasonId === seasonId) {
            uniqueIds.add(task.id);
            uniqueTasks.push(task);
          }
        }
      });
      return uniqueTasks;
    },
    getSeasonalTaskByDay: state => (additionalDays: number) => {
      const now = getNow();
      now.setUTCDate(now.getDate() + additionalDays);
      if (state.actualSeason) {
        for (const taskId of state.actualSeason.tasks) {
          const seasonTask = state.allTasks?.find(task => task.id === taskId);
          if (seasonTask?.start && seasonTask?.deadline) {
            if (
              new Date(seasonTask.start) <= now &&
              new Date(seasonTask.deadline) > now
            ) {
              return seasonTask;
            }
          }
        }
      }
      return null;
    },
    getTypeOfTasksView: state => () => {
      return state.typeOfTasksView;
    },
    getLocationByTaskId: state => (id: TaskId) => {
      return state.locations.find(location =>
        location.tasks.some(taskId => taskId === id),
      );
    },
    getSeason: state => () => {
      const now = getNow();
      if (
        state.actualSeason &&
        new Date(state.actualSeason.startTime) <= now &&
        new Date(state.actualSeason.endTime) > now
      ) {
        return state.actualSeason;
      }
      state.actualSeason =
        state.seasons?.find(season => {
          return (
            new Date(season.startTime) <= now && new Date(season.endTime) > now
          );
        }) || null;
      return state.actualSeason;
    },
  },
  actions: {
    async fetchUser() {
      const responseUser = await fetchUser();
      this.user = responseUser.value;
    },
    async registerUser(referralCode: string) {
      const response = await registerUser({ referral_code: referralCode });
      this.shouldResetReferrals =
        !!this.user &&
        this.user?.referrals_count !== response.value.referrals_count;
      this.user = response.value;
    },
    async fetchReferralCode() {
      const response = await fetchReferralCode();
      this.referralCode = response.value;
    },
    async fetchReferrals() {
      try {
        this.isFetchingReferrals = true;
        const reset = this.shouldResetReferrals;
        const offset = reset ? 0 : this.referrals.length;
        const response = await fetchReferrals(offset, REFERRALS_PER_PAGE);
        this.referrals = reset
          ? response.value
          : [...this.referrals, ...response.value];
      } finally {
        this.isFetchingReferrals = false;
      }
    },
    async fetchReferralInfo() {
      await Promise.all([this.fetchReferralCode(), this.fetchReferrals()]);
    },
    async fetchCompletedTasks() {
      if (!this.user) {
        return;
      }
      const completedTasks = await fetchCompletedTasks();
      this.completedTasks = completedTasks.value;
    },
    async fetchLocations() {
      const response = await axios.get('/tasks.json', {
        headers: { 'Cache-Control': 'no-cache' },
      });
      this.locations = response.data.locations;
    },
    async fetchAllTasks() {
      if (!this.user) {
        return;
      }
      const response = await axios.get('/tasks.json', {
        headers: { 'Cache-Control': 'no-cache' },
      });
      const allTasks = await fetchAllTasks();
      this.allTasks = response.data.tasks.flatMap((task: Task) => {
        return allTasks.value
          .filter(t => t.task_id === task.id)
          .map(fetchedTask => {
            const mergedTask = {
              ...task,
              deadline: fetchedTask.deadline
                ? fetchedTask.deadline + 'Z'
                : null,
              seasonId: fetchedTask.season_id,
              start: fetchedTask.start ? fetchedTask.start + 'Z' : null,
            };
            if (task.type === TaskType.Survey && fetchedTask.season_id) {
              return {
                ...mergedTask,
                reward: {
                  choice: fetchedTask.choice,
                  score: fetchedTask.score,
                },
              };
            }
            return mergedTask;
          });
      });
    },
    async fetchSeasons() {
      if (!this.user) {
        return;
      }
      const seasons = await fetchSeasons();
      this.seasons = seasons.value.map(season => {
        return {
          id: season.season_id,
          endTime: season.end_time + 'Z',
          claimEndTime: season.claim_end_time + 'Z',
          startTime: season.start_time + 'Z',
          title: `Season ${season.season_id}`,
          tasks: [],
        };
      });
      this.setSeasonsTasks();
      this.setSeason();
    },
    async fetchSeasonReward() {
      if (!this.user && !this.seasons) {
        this.seasonReward = null;
        return;
      }
      const now = getNow();
      const season = this.seasons?.find(season => {
        return (
          new Date(season.endTime) <= now && new Date(season.claimEndTime) > now
        );
      });
      if (!season) {
        this.seasonReward = null;
      } else {
        const seasonReward = (await fetchSeasonReward(season.id)).value;
        this.seasonReward = {
          claimEndTime: seasonReward.claim_end_time,
          claimStartTime: seasonReward.claim_start_time,
          rewardScore: seasonReward.reward_score,
          seasonId: seasonReward.season_id,
          title: season.title,
          claimed: seasonReward.claimed,
        };
      }
    },
    async checkIn() {
      const response = await checkIn();
      this.user = response.value;
    },
    resetStreak() {
      if (!this.user) {
        return;
      }
      this.user.check_in_streak = 0;
    },
    setIsFirstRender(value: boolean) {
      this.isFirstRender = value;
    },
    setTypeOfTasksView(type: TasksListType) {
      this.typeOfTasksView = type;
    },
    async claimReferralReward() {
      const response = await claimReferralReward();
      this.user = response.value;
    },
    async claimSeasonReward() {
      const now = getNow();
      const season = this.seasons?.find(season => {
        return (
          new Date(season.endTime) < now && new Date(season.claimEndTime) > now
        );
      });
      if (season) {
        const response = await claimSeasonReward(season.id);
        this.user = response.value;
      }
    },
    setSeason() {
      const now = getNow();
      this.actualSeason =
        this.seasons?.find(season => {
          return (
            new Date(season.startTime) <= now && new Date(season.endTime) > now
          );
        }) || null;
    },
    setSeasonsTasks() {
      if (!this.seasons) {
        return;
      }
      for (const season of this.seasons) {
        const seasonTasks = this.allTasks?.filter(task => {
          if (task.seasonId === season.id) {
            return task.id;
          }
        });
        season.tasks =
          seasonTasks
            ?.filter(
              (task, index, tasks) =>
                index === tasks.findIndex(t => t.id === task.id),
            )
            .map(task => task.id) || [];
      }
    },
  },
});
