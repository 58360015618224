<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import useStore from '@/store';
import ArrowRightIcon from './icons/ArrowRightIcon.vue';
import CheckIcon from './icons/CheckIcon.vue';
import { PageName } from '@/types';
import { useHapticFeedback } from '@/directives/haptic';
import type { TaskId } from '@/types/tasks';

const DEFAULT_HEIGHT = 66;
const clipPathId = `clip-path-${Math.random().toString(36).slice(2, 11)}`;

const props = defineProps<{
  taskId: TaskId;
}>();

const router = useRouter();
const store = useStore();

const width = ref(0);
const height = ref(0);

let resizeObserver: ResizeObserver | null = null;

const contentElement = ref<HTMLElement | null>(null);

const task = computed(() => {
  return store.getTaskById(props.taskId);
});

const completed = computed(() =>
  (store.completedTasks ?? []).some(task => task.task_id === props.taskId),
);

const pathData = computed(() => {
  const pathWidth = (width.value / height.value) * DEFAULT_HEIGHT;
  return `M${
    pathWidth - 20
  } 9H21C14.37 9 9 14.37 9 21v2.13l-6.5 3.75c-2 1.15-2 4.04 0 5.2L9 35.83V47c0 6.63 5.37 12 12 12h${
    pathWidth - 40
  }c6.63 0 12-5.37 12-12V21c0-6.63-5.37-12-12-12z`;
});

const clipPathStyle = computed(() => ({
  clipPath: `url(#${clipPathId})`,
  width: '100%',
  height: '100%',
}));

const viewBox = computed(() => {
  const pathWidth =
    height.value > 0 ? (width.value / height.value) * DEFAULT_HEIGHT : 0;
  return `0 0 ${pathWidth} ${DEFAULT_HEIGHT}`;
});

function updateDimensions() {
  if (contentElement.value) {
    width.value = contentElement.value.clientWidth;
    height.value = contentElement.value.clientHeight;
  }
}

function openTask() {
  useHapticFeedback('soft');
  router.push({ name: PageName.Task, params: { id: props.taskId } });
}

onMounted(() => {
  updateDimensions();
  resizeObserver = new ResizeObserver(() => {
    updateDimensions();
  });
  if (contentElement.value) {
    resizeObserver.observe(contentElement.value);
  }
});

onBeforeUnmount(() => {
  if (resizeObserver && contentElement.value) {
    resizeObserver.unobserve(contentElement.value);
    resizeObserver.disconnect();
  }
});
</script>

<template>
  <div class="task-on-map" @click="openTask">
    <svg
      class="task-on-map__clip-path"
      v-if="width"
      :width="width"
      :height="height"
      :viewBox="viewBox"
    >
      <defs>
        <clipPath :id="clipPathId">
          <path
            :d="pathData"
            :transform="`scale(${height / DEFAULT_HEIGHT})`"
          />
        </clipPath>
      </defs>
    </svg>
    <div :style="clipPathStyle" class="task-on-map__clipped-background"></div>
    <svg
      v-if="width"
      class="task-on-map__outline"
      :width="width"
      :height="height"
      :viewBox="viewBox"
      preserveAspectRatio="none"
    >
      <path
        :d="pathData"
        width="100%"
        height="100%"
        fill="none"
        stroke="rgba(255, 255, 255, 0.3)"
        stroke-width="1"
      />
    </svg>
    <div class="task-on-map__content" ref="contentElement">
      <div
        class="task-on-map__info"
        :class="{
          'task-on-map__info--completed': completed,
        }"
      >
        <div class="task-on-map__text">
          <span
            class="task-on-map__title"
            :class="{
              'task-on-map__title--multiline': task?.multilineTitle,
            }"
            >{{ task ? task.title : ''
            }}{{ task?.multilineTitle ? '   ' : '' }}</span
          >
          <br v-if="!task?.multilineTitle" />
          <div class="task-on-map__reward">
            <picture>
              <source srcset="img/coin.avif" type="image/avif" />
              <source srcset="img/coin.png" type="image/png" />
              <img class="task-on-map__coin" src="img/coin.png" alt="Coin" />
            </picture>
            <span>+{{ task?.reward }}</span>
          </div>
        </div>
      </div>
      <div class="task-on-map__status">
        <CheckIcon v-if="completed" />
        <ArrowRightIcon v-else />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$default-height: 66;

.task-on-map {
  position: relative;
  display: inline-block;
  height: 17.5vw;
  margin: calc(-20vw * 8 / $default-height);
  cursor: pointer;

  &__clip-path {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__clipped-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 20%);
    backdrop-filter: blur(5vw);
  }

  &__outline {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: calc(20vw * 8 / $default-height);
    font-weight: 600;
    font-size: 3.5vw;
  }

  &__info {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 4vw;

    &--completed {
      opacity: 0.2;
    }
  }

  &__title {
    white-space: nowrap;

    &--multiline {
      white-space: pre-wrap;
    }
  }

  &__reward {
    vertical-align: top;
    display: inline-flex;
    align-items: center;
    gap: 1vw;
    color: #ff676e;
  }

  &__coin {
    width: 4vw;
    height: 4vw;
  }

  &__status {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8vw;
    height: 8vw;
    margin: 0 2.5vw;
    background: rgba(black, 12%);
    border-radius: 50%;

    svg {
      height: 3.5vw;
      width: auto;
      max-width: 4vw;
      fill: white;
    }
  }
}
</style>
