<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useStore from '@/store';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { PageName } from '@/types';
import { useHapticFeedback } from '@/directives/haptic';
import { completeTask } from '@/utils/api';
import {
  StepType,
  TaskType,
  type RewardWithChoice,
  type TaskId,
} from '@/types/tasks';

const route = useRoute();
const router = useRouter();
const store = useStore();

const stepIndex = ref(0);
const option = ref<number | null>(null);
const loading = ref(false);

const user = ref(store.user);
const season = ref(store.getSeason());
const actualTask = ref(store.getSeasonalTaskByDay(0));
const nextTask = ref(store.getSeasonalTaskByDay(1));
const task = ref(store.getTaskById(Number(route.params.id) as TaskId));

const step = computed(() => {
  if (task.value && task.value.type === TaskType.Survey) {
    return task.value.steps[stepIndex.value];
  }
  return null;
});

const scoreByChoice = computed(() => {
  if (task.value && task.value.seasonId && store.allTasks) {
    return store.allTasks
      .reduce<RewardWithChoice[]>((acc, { id, reward }) => {
        const score: number | null = (reward as RewardWithChoice).score;
        const choice: number | null = (reward as RewardWithChoice).choice;
        if (
          id === task.value?.id &&
          typeof score === 'number' &&
          typeof choice === 'number'
        ) {
          // Filtering condition
          acc.push({
            score, // Mapping logic
            choice,
          });
        }
        return acc;
      }, [])
      .sort((reward1, reward2) => reward1.choice - reward2.choice);
  }
  return null;
});

const coverSrc = computed(() => {
  if (step.value?.image) {
    return `/img/tasks/tasks/${step.value?.image}`;
  }
  return `/img/tasks/tasks/${task.value?.image}`;
});

const actionButtonDisabled = computed(() => {
  return step.value?.type === StepType.Question && option.value === null;
});

const actionButtonText = computed(() => {
  if (step.value) {
    if (step.value?.type === StepType.Question) {
      if (option.value === null) {
        return step.value.chooseText ?? 'Make your choice';
      } else {
        return step.value.actionText ?? 'Unveil the sacred faith';
      }
    } else if (step.value?.type === StepType.Info) {
      return step.value.continueText ?? 'Continue';
    }
  }
  return '';
});

const isThisLastStep = computed(() => {
  return (
    task.value &&
    task.value.type === TaskType.Survey &&
    stepIndex.value === task.value?.steps.length - 1
  );
});

const text = computed(() => {
  if (!step.value) return '';
  return step.value.type === StepType.Question
    ? step.value.question
    : step.value.description;
});

const performAction = async () => {
  if (!task.value) {
    useHapticFeedback('error');
    return;
  }
  if (isThisLastStep.value) {
    if (option.value === null) {
      useHapticFeedback('error');
      return;
    }
    loading.value = true;
    useHapticFeedback('success');
    if (task.value.seasonId) {
      // because the numbering of the selection on the backend begins with 1
      await completeTask(task.value?.id, option.value + 1);
    } else {
      await completeTask(task.value?.id);
    }
    await store.fetchCompletedTasks();
    await store.fetchUser();

    if (task.value.seasonId) {
      router.push({
        name: PageName.Season,
      });
    } else if (isThisLastStep.value) {
      router.push({
        name: PageName.Task,
        params: { id: task.value?.id },
      });
    }
    loading.value = false;
  } else {
    useHapticFeedback('soft');
    stepIndex.value++;
  }
};

const selectOption = (opt: number) => {
  option.value = opt;
};

const goBack = () => {
  if (stepIndex.value > 0) {
    stepIndex.value--;
    option.value = null;
  } else if (task.value?.seasonId) {
    router.push({
      name: PageName.Season,
    });
  } else {
    router.push({
      name: PageName.Task,
      params: { id: task.value?.id },
    });
  }
};

watch(store, () => {
  user.value = store.user;
  task.value = store.getTaskById(Number(route.params.id) as TaskId);
  season.value = store.getSeason();
  actualTask.value = store.getSeasonalTaskByDay(0);
  nextTask.value = store.getSeasonalTaskByDay(1);
});
</script>

<template>
  <div v-if="task" class="survey-view">
    <div v-haptic="'soft'" class="back-button" @click="goBack">
      <ArrowLeftIcon />
    </div>
    <div class="cover">
      <picture>
        <source :srcset="coverSrc + '.avif'" type="image/avif" />
        <source :srcset="coverSrc + '.jpg'" type="image/jpeg" />
        <img :src="coverSrc + '.jpg'" alt="Quest step cover" />
      </picture>
      <div class="cover-blackout"></div>
    </div>
    <div class="text">
      {{ text }}
    </div>
    <div v-if="step?.type === StepType.Question" class="options">
      <div
        v-haptic="'soft'"
        v-for="(item, index) in step?.options"
        :key="index"
        class="option"
        :class="{
          'option--selected': option === index,
          'option--season': task.seasonId,
          'option--disabled': scoreByChoice
            ? (user?.score ?? 0) + scoreByChoice[index]?.score <= 0
            : false,
        }"
        @click="selectOption(index)"
      >
        {{ item }}
        <div
          v-if="scoreByChoice"
          class="score"
          :class="[
            'score',
            {
              'score--minus': scoreByChoice[index]?.score < 0,
              'score--plus': scoreByChoice[index]?.score > 0,
            },
          ]"
        >
          {{ scoreByChoice[index].score }}
          <div class="icon">
            <picture>
              <source srcset="img/coin.avif" type="image/avif" />
              <source srcset="img/coin.png" type="image/png" />
              <img class="coin" src="img/coin.png" alt="Coin" />
            </picture>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step?.showReward" class="reward">
      <span class="reward-title">Total reward</span>
      <span class="reward-value">+{{ task?.reward }}</span>
      <picture>
        <source srcset="img/coin.avif" type="image/avif" />
        <source srcset="img/coin.png" type="image/png" />
        <img class="coin" src="img/coin.png" alt="Coin" />
      </picture>
    </div>
    <div class="action-button__wrapper">
      <LoadingSpinner v-if="true" class="action-button__loading-spinner" />
      <button
        :style="{
          opacity: loading ? 0 : 1,
          pointerEvents: loading ? 'none' : 'auto',
        }"
        class="action-button"
        :disabled="actionButtonDisabled"
        @click="performAction"
      >
        {{ actionButtonText }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.survey-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 26vw;

  .back-button {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 10vw;
    background: #141414;
    top: 4vw;
    left: 4vw;
    border-radius: 100%;
    z-index: 1000;
    cursor: pointer;

    svg {
      width: 6vw;
      height: 6vw;
      fill: white;
    }
  }

  .cover {
    position: relative;
    width: 100%;
    height: 35vh;

    picture {
      display: block;
      height: 100%;
      padding-bottom: 2px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }
  }

  .cover-blackout {
    position: absolute;
    height: 30vh;
    width: 100vw;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #0e0e0e 100%);
  }

  .text {
    position: relative;
    width: 92vw;
    margin-top: 4vw;
    font-size: 4vw;
    text-align: center;
    color: #8b8b8b;
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vw;
    width: 92vw;
    margin-top: 8vw;

    .score {
      margin-left: 0;
      margin-right: 0;
      gap: 1vw;
    }

    .title {
      position: relative;
      width: 92vw;
      font-size: 7vw;
      line-height: 100%;
      text-align: center;
    }

    .description {
      position: relative;
      width: 92vw;
      margin-top: 1vw;
      font-size: 4vw;
      text-align: center;
      color: #8b8b8b;
    }
  }

  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background: rgba(61, 53, 53, 0.4);
    border-radius: 4vw;
    min-height: 13vw;
    padding: 2.5vw;
    line-height: 130%;
    cursor: pointer;
    border: 1px solid transparent;

    &--selected {
      background: rgba(255, 255, 255, 0.12);
      border: 1px solid rgba(255, 255, 255, 1);
    }

    &--season {
      text-align: left;
      justify-content: space-between;
      gap: 5vw;
    }
    &--disabled {
      pointer-events: none;
      .score {
        opacity: 0.5;
      }
    }
  }
  .score {
    font-size: 2vh;
    font-weight: 400;
    align-items: center;
    display: flex;
    margin-left: 4vh;
    margin-right: 1vh;

    &--plus {
      background: linear-gradient(
        90deg,
        #c8a378 0%,
        #ffdcb3 55.32%,
        #f6d199 100%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &--minus {
      background: linear-gradient(180deg, #ff3347 0%, #ec5161 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .icon {
    width: 7vw;
  }
  .reward {
    position: relative;
    display: flex;
    align-items: center;
    width: 84vw;
    height: 10vw;
    padding: 0 2vw;
    margin-top: 8vw;
    border-radius: 3vw;
    background: #1d1c1b;
  }

  .reward-title {
    font-size: 4vw;
    font-weight: 400;
    padding: 2vw;
  }

  .reward-value {
    font-size: 4.5vw;
    font-weight: 400;
    background: linear-gradient(
      90deg,
      #c8a378 0%,
      #ffdcb3 55.32%,
      #f6d199 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: auto;
    margin-right: 2vw;
  }

  .coin {
    width: 6vw;
    height: 6vw;
    margin-right: 2vw;
  }

  .action-button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4vw 8vw;
    z-index: 1000;

    background: #e3232c;
    box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
    border-radius: 100vw;

    color: white;
    font-size: 4vw;
    font-weight: 700;
    border: 1px solid transparent;

    &--secondary {
      background: white;
      box-shadow: 0 1vw 5vw rgba(255, 255, 255, 0.5);
      color: black;
    }

    &:disabled {
      background: #0e0e0e;
      box-shadow: none;
      border: 1px solid rgba(255, 255, 255, 0.2);
      color: rgba(255, 255, 255, 0.5);
      cursor: not-allowed;
    }

    &__wrapper {
      position: fixed;
      display: flex;
      justify-content: center;
      left: 4vw;
      bottom: 6vw;
      width: 92vw;
    }

    &__loading-spinner {
      position: absolute;
      height: 200%;
      top: -50%;
    }
  }
}
</style>
